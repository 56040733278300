/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const EventRedirectPage = () => {
  const location = useLocation();
  const eventRedirect = useLocation().search;
  const index = eventRedirect.includes("&")
    ? eventRedirect.indexOf("&")
    : eventRedirect.length;
  const eventFromRedirect = eventRedirect.substring(0, index).replace("?", "");

  const navigate = useNavigate();


  useEffect(() => {
    
  const eventId = eventFromRedirect
  ? eventFromRedirect
  : localStorage.getItem("eventId") || location.state?.eventId;

  navigate(`/event/${eventId}/_`)

  }, []);

  return (
    <>
    </>
  );
};

export default EventRedirectPage;
