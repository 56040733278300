import React from "react";
import { useNavigate } from "react-router";

export const PageHeader = ({ text }) => {
  const navigate = useNavigate();

  return (
    <div className="modal-header text-left my-2 py-3">
      <h5 className="font15 text-white z-index1 mb-0">{text}</h5>
      <button
        type="button"
        className="btn-close"
        onClick={() => navigate(-1)}
      ></button>
    </div>
  );
};