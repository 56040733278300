import React, { useState, useEffect } from "react";

import axios from "axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { InputLabeled } from "../common/InputLabeled";
import { CustomDatePicker } from "../common/DateInput";
import { CalendarContainer } from "react-datepicker";
import { backendUrl, checkReferee } from "../utils/fetchUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import authHeader from "../../service/auth-header";
import { MyButton } from "../common/Button";
import { useNavigate, useParams } from "react-router";
import { PageHeader } from "../common/PageHeader";

export const ManageEventPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [myEvent, setMyEvent] = useState({
    name: "",
    headDescription: "",
    description: "",
    footerDescription: "",
    date: new Date(),
    signDeadline: new Date(),
    signStart: new Date(),
    admin: 1,
    stages: [],
    organizer: "",
    logoPath: "",
    awdClassification: false,
    rwdClassification: false,
    fwdClassification: false,
    pzm: true,
  });
  const [eventPaths, setEventPaths] = useState([]);
  const [path, setPath] = useState({
    eventId: params.eventId,
    path: "",
    description: "",
  });
  const [eventFiles, setEventFiles] = useState([]);
  const [file, setFile] = useState({
    eventId: params.eventId,
    file: "",
    fileName: "",
    description: "",
  });
  const [logoFile, setLogoFile] = useState();
  const [stages, setStages] = useState([]);
  const [referee, setReferee] = useState([]);
  const [refereeOptions, setRefereeOptions] = useState([]);
  const [eventClasses, setEventClasses] = useState([]);
  const [eventClassesOptions, setEventClassesOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isReferee, setIsReferee] = useState(false);

  useEffect(() => {
    if (params?.eventId) checkReferee(params.eventId, setIsReferee);
  }, []);

  useEffect(() => {
    fetchEventToEdit();

    axios
      .get(`${backendUrl()}/event/getRefereeOptions`, {
        headers: authHeader(),
      })
      .then((res) => {
        setRefereeOptions(res.data);
      });

    axios
      .get(`${backendUrl()}/event/getEventClassesOptions`, {
        headers: authHeader(),
      })
      .then((res) => {
        setEventClassesOptions(res.data);
      });

    setEventClasses([]);
    setReferee([]);
    setStages([]);
  }, []);

  const handleChange = (event) => {
    setMyEvent({ ...myEvent, [event.target.name]: event.target.value });
  };

  const handleAccept = () => {
    setSaving(true);
    const data = {
      ...myEvent,
      stages: stages,
      referee: referee,
      eventClasses: eventClasses,
      eventPaths: eventPaths,
      eventFiles: [],
    };

    axios
      .put(`${backendUrl()}/event/createNew`, data)
      .then((res) => {
        eventFiles.forEach((f) =>
          axios.post(
            `${backendUrl()}/event/addFileToEvent?fileName=${f.fileName}&desc=${
              f.description
            }&eventId=${res.data}`,
            f.file,
            {
              headers: authHeader(),
            }
          )
        );
        if (logoFile)
          axios.post(
            `${backendUrl()}/event/addLogoFile?eventId=${res.data}`,
            logoFile,
            {
              headers: authHeader(),
            }
          );
      })
      .then(() => navigate(-1))
      .finally(() => {
        setSaving(false);
      });
  };

  const removeFile = (fileId) => {
    if (!fileId) return;

    axios.post(
      `${backendUrl()}/event/removeFileFromEvent?fileId=${fileId}&eventId=${
        myEvent.eventId
      }`,
      {
        headers: authHeader(),
      }
    );
  };

  const addPath = () => {
    eventPaths.push(path);
    setPath({
      eventId: params.eventId,
      path: "",
      description: "",
    });
  };

  const addFiles = () => {
    eventFiles.push(file);
    setFile({
      eventId: params.eventId,
      file: "",
      fileName: "",
      description: "",
    });
  };

  const fetchEventToEdit = () => {
    axios
      .get(`${backendUrl()}/event/getEvent?eventId=${params.eventId}`)
      .then((res) => {
        setMyEvent({
          ...res.data,
          date: res.data.date ? new Date(res.data.date) : new Date(),
          signDeadline: res.data.signDeadline
            ? new Date(res.data.signDeadline)
            : new Date(),
          signStart: res.data.signStart
            ? new Date(res.data.signStart)
            : new Date(),
        });
        setStages(
          res.data.stages.map((x) => {
            return { ...x, startTime: new Date(x.startTime) };
          })
        );
        setReferee(res.data.referee);
        setEventClasses(
          res.data.eventClasses.map((x) => {
            return { ...x, carClassName: x.carClass?.name };
          })
        );
        setEventPaths(res.data.eventPaths);
        setEventFiles(res.data.eventFiles);
      });
  };

  const addFile = (e) => {
    const currentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", currentFile);

    setFile({ ...file, file: formData, fileName: currentFile.name });
  };

  const addLogoFile = (e) => {
    const currentFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", currentFile);

    setLogoFile(formData);
  };

  const DatePickerContainer = ({ className, children }) => {
    return (
      <>
        <CalendarContainer className={className}>
          <div style={{ display: "flex" }}>{children}</div>
        </CalendarContainer>
      </>
    );
  };

  return (
    <>
      {!isReferee ? (
        <div className="bg-white row u-text-center justify-content-center px-0 mx-0">
          <h2>Brak uprawnień</h2>
        </div>
      ) : (
        <>
          <PageHeader
            text={"Edycja wydarzenia - " + params.eventName}
          ></PageHeader>
          <div className="bg-white row u-text-center justify-content-center px-0 mx-0">
            <Card.Body className="py-0">
              <div className="row d-flex">
                <div className="col-lg-3 px-1">
                  <InputLabeled
                    label="Nazwa"
                    name="name"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.name}
                    multiline={2}
                  />
                  <InputLabeled
                    label="Limit załóg"
                    name="teamLimit"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.teamLimit}
                  />
                  <InputLabeled
                    label="Nazwa organizatora"
                    name="organizer"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.organizer}
                  />
                  <CustomDatePicker
                    label={"Data wydarzenia"}
                    selected={myEvent.date}
                    disabled={true}
                    minDate={null}
                    maxDate={null}
                  />
                  <CustomDatePicker
                    label={"Koniec zapisów"}
                    onChange={(value) =>
                      setMyEvent({ ...myEvent, signDeadline: value })
                    }
                    selected={myEvent.signDeadline}
                    calendarContainer={DatePickerContainer}
                    minDate={new Date()}
                    maxDate={null}
                  />
                  <CustomDatePicker
                    label={"Start zapisów"}
                    onChange={(value) =>
                      setMyEvent({ ...myEvent, signStart: value })
                    }
                    selected={myEvent.signStart}
                    calendarContainer={DatePickerContainer}
                    minDate={new Date()}
                    maxDate={myEvent.signDeadline}
                  />
                  <Card className="text-center font14">
                    <Card.Header className="bg-dark-green text-white">
                      Plik z logo wydarzenia
                    </Card.Header>
                    <Card.Body className="p-1">
                      <input
                        type="file"
                        name="logoPathFile"
                        accept="image/jpg"
                        onChange={(e) => addLogoFile(e)}
                      />
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-lg-9 px-1">
                  <InputLabeled
                    label="Opis główny"
                    name="headDescription"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.headDescription}
                    multiline={10}
                  />
                  <InputLabeled
                    label="Opis"
                    name="description"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.description}
                    multiline={10}
                  />
                  <InputLabeled
                    label="Opis stopka"
                    name="footerDescription"
                    handleChange={handleChange}
                    big={true}
                    value={myEvent.footerDescription}
                    multiline={8}
                  />
                </div>
              </div>
            </Card.Body>
            <div className="col-lg-5 py-2  border-right shadow bg-white rounded">
              <div className="col-lg-12 border-right rounded">
                <Card className=" font14">
                  <Card.Header className="bg-dark-green text-white">
                    Odcinki PS/OS (w kolejności)
                  </Card.Header>
                  <Card.Body className="p-0">
                    {stages.length > 0 && (
                      <Table responsive className="font14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nazwa</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stages.map((x, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{x.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Card>
                <Card className="text-center font14">
                  <Card.Header className="bg-dark-green text-white">
                    Sędziowie
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Table responsive className="font14">
                      <thead>
                        <tr>
                          <th>Login</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referee.map((x, index) => (
                          <tr key={x.userId + index}>
                            <td>{x.username}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="col-lg-7 py-2 shadow mb-1 bg-white rounded">
              <Card className="text-center font14">
                <Card.Header className="bg-dark-green text-white">
                  Linki do regulaminow, informacji itd.
                </Card.Header>
                <Card.Body className="p-0">
                  <Table responsive className="font14">
                    <thead>
                      <tr>
                        <th>Link</th>
                        <th>Opis</th>
                        <th className="text-end">Usuń</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventPaths.map((path, index) => (
                        <tr key={index}>
                          <td>{path.path}</td>
                          <td>{path.description}</td>
                          <td className="text-end">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={() =>
                                setEventPaths(
                                  eventPaths.filter(
                                    (elem) => elem.path !== path.path
                                  )
                                )
                              }
                              title={"Usuń link"}
                              cursor={"pointer"}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-grid">
                    <InputLabeled
                      label="Link/ścieżka"
                      name="path"
                      handleChange={(e) =>
                        setPath({ ...path, path: e.target.value })
                      }
                      big={true}
                      value={path.path}
                    />
                    <InputLabeled
                      label="Opis"
                      name="description"
                      handleChange={(e) =>
                        setPath({ ...path, description: e.target.value })
                      }
                      big={true}
                      value={path.description}
                    />
                  </div>
                  <Button
                    className={"px-4 my-1 font14"}
                    variant="success"
                    onClick={addPath}
                    disabled={path.description === "" || path.link === ""}
                  >
                    Dodaj link
                  </Button>
                </Card.Body>
              </Card>
              <Card className="text-center font14">
                <Card.Header className="bg-dark-green text-white">
                  Pliki
                </Card.Header>
                <Card.Body className="p-0">
                  <Table responsive className="font14">
                    <thead>
                      <tr>
                        <th>Plik</th>
                        <th>Opis</th>
                        <th className="text-end">Usuń</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventFiles.map((file, index) => (
                        <tr key={index}>
                          <td>{file.fileName}</td>
                          <td>{file.description}</td>
                          <td className="text-end">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={() => {
                                removeFile(file.id);
                                setEventFiles(
                                  eventFiles.filter(
                                    (elem) => elem.fileName !== file.fileName
                                  )
                                );
                              }}
                              title={"Usuń plik"}
                              cursor={"pointer"}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-grid">
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => addFile(e)}
                    />
                    <InputLabeled
                      label="Opis"
                      name="description"
                      handleChange={(e) =>
                        setFile({ ...file, description: e.target.value })
                      }
                      big={true}
                      value={file.description}
                    />
                  </div>
                  <Button
                    className={"px-4 my-1 font14"}
                    variant="success"
                    onClick={addFiles}
                    disabled={file.description === "" || file.fileName === ""}
                  >
                    Dodaj plik
                  </Button>
                </Card.Body>
              </Card>
            </div>
            <div className={"border-top justify-content-center py-3"}>
              <Button
                className={"mx-3"}
                variant="secondary"
                onClick={() => navigate(-1)}
              >
                Anuluj
              </Button>
              <MyButton
                className={"px-4 mx-3"}
                variant="success"
                onClick={handleAccept}
                disabled={stages.length === 0}
                isLoading={saving}
                msg="OK"
                loadingMsg="Trwa zapisywanie"
              />
            </div>
          </div>
        </>
      )}
      );
    </>
  );
};
